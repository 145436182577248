import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { RichTextPage, RichTextPageProps } from "../components/RichText";
import { IAllMarkdownRemark, IFrontmatter } from "./interfaces";

export const RichTextContainer: React.FC = () => {
  const render = (
    data: IAllMarkdownRemark<IFrontmatter<RichTextPageProps>>
  ) => {
    const {
      title,
      description
    } = data.allMarkdownRemark.edges[0].node.frontmatter;
    return <RichTextPage title={title} description={description} />;
  };
  return (
    <StaticQuery
      query={graphql`
        query RichTextContainerQuery {
          allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "careers-page" } } }
          ) {
            edges {
              node {
                frontmatter {
                  title
                  description
                }
              }
            }
          }
        }
      `}
      render={render}
    />
  );
};
