import styled from "@emotion/styled";
import React from "react";
import ReactMarkdown from "react-markdown";
import breakpoints from "../../breakpoints";
import { isBrowser } from "../../hooks/useScrollPosition";
import { MemoizedLink } from "../../templates/MemoizedLink";
import { HobTypography } from "../HobTypography";
import { Navbar } from "../Navbar/";

export interface RichTextPageProps {
  title: string;
  description: string;
}

const Container = styled.div`
  min-height: 100vh;
  background-color: var(--hob-color--secondary);
  position: relative;
  padding: 1.5rem 1.25rem;
  padding-top: 1.77rem;
  z-index: 2;

  @media (min-width: 768px) {
    min-height: calc(100vh - 104px);
  }
`;

const Body = styled.section`
  @media (min-width: 768px) {
    width: 75%;
    .hob-typography--body1 {
      line-height: 1.278em;
      font-size: 1.75rem;
    }
  }
`;

const Description = styled.div`
  flex: 1;
  min-width: 480px;
  margin-top: 2.5rem;
  margin-right: 2.5rem;
  margin-bottom: 1.25rem;

  p {
    margin-bottom: 1rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ${breakpoints.mobile} {
    flex: unset;
    margin-right: 0;
    margin-bottom: 3rem;
    min-width: 0;
  }
`;

interface Position {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

interface State {
  scrollDirection: "north" | "south";
  scrollY: number;
  positions: {
    studio: Position;
    nav: Position;
  };
  windowHeight: 0;
  studioInView: boolean;
}

export const RichTextPage: React.FC<RichTextPageProps> = ({
  title,
  description
}: RichTextPageProps) => {
  let height = 28;

  if (isBrowser) {
    height = window.screen.width < 600 ? 22 : 28;
  }

  return (
    <React.Fragment>
      <Navbar className="nav nav--home">
        <MemoizedLink
          href="/#work"
          label="Work"
          color="primary"
          height={height}
          offset={0}
        />
        <MemoizedLink
          href="/#studio"
          label="Studio"
          color="primary"
          height={height}
          offset={0}
        />
      </Navbar>
      <Container className="main main--home main--north">
        <HobTypography variant="h2">{title}</HobTypography>
        <Body>
          <Description className="rich-text" variant="body1">
            {<ReactMarkdown source={description} />}
          </Description>
        </Body>
      </Container>
    </React.Fragment>
  );
};
